import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './Product.css';

const Product = ({ product }) => {
    const { name, images, _id } = product;  // `img` yerine `images` kullanılıyor.
    const history = useHistory();

    // `images` bir dizi olduğu için doğrudan kullanabiliriz. Eğer `images` boşsa, varsayılan bir görsel gösterebiliriz.
    const imagesArray = images && images.length > 0 ? images : ['/path/to/default-image.jpg'];

    // Görsel değişimini kontrol etmek için bir `state` kullanıyoruz.
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Fare hareketini izleyen fonksiyon
    const handleMouseMove = (e) => {
        // Ekranın genişliğine bağlı olarak fare hareketini yüzde olarak hesapla
        const containerWidth = e.currentTarget.clientWidth;
        const mouseX = e.nativeEvent.offsetX;

        // Fare konumuna göre hangi görselin gösterileceğini hesapla
        const newIndex = Math.floor((mouseX / containerWidth) * imagesArray.length);
        setCurrentImageIndex(newIndex);
    };

    // Satın alma butonuna tıklama işlevi
    const handlePurchase = (id) => {
        history.push(`/jewellery/${id}`);
    };

    return (
        <div className='card-container'>
            {/* Görsel Konteyneri: Fare hareketiyle değişim için `onMouseMove` event'i ekledik */}
            <div className='photo' onMouseMove={handleMouseMove} style={{ position: 'relative', overflow: 'hidden' }}>
                <img
                    className='d-block w-100'
                    src={`https://jewellery-server-main.onrender.com/${imagesArray[currentImageIndex]}`} // Görsel URL'sini backend adresi ile birleştiriyoruz
                    alt={`product-img-${currentImageIndex}`}
                    style={{ height: '300px', objectFit: 'cover' }} // Görselin uygun yükseklik ve biçim ayarı
                />
            </div>
            <Card.Body className='photo-detail'>
                <h6 className='fs-5 fw-normal text-muted'>{name.slice(0, 15)}</h6>
                <button onClick={() => handlePurchase(_id)} className='btn-regular btn-custom'>İncele</button>
            </Card.Body>
        </div>
    );
};

export default Product;
