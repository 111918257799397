import React from 'react';
import swal from 'sweetalert';

const ManageProductCard = ({ product, setNumber }) => {
    const { images, name, price, stock, _id } = product;

    const btnDelete = {
        border: 'none',
        backgroundColor: "#dda06b",
        color: "#fff",
        fontSize: "17px",
        borderRadius: "3px"
    };

    const handleDelete = id => {
        swal({
            title: "Ürünü Silmek İstediğinize Emin Misiniz ?",
            text: "Silindikten sonra bu dosyayı kurtarmanız mümkün olmayacaktır!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(isOkey => {
            if (isOkey) {
                fetch(`https://jewellery-server-main.onrender.com/products/${id}`, {
                    method: 'DELETE',
                })
                    .then(res => res.json())
                    .then(data => {
                        if (data.deletedCount > 0) {
                            swal("🤭", "Silme İşlemi Başarılı", "success");
                            setNumber(prev => prev + 1);
                        }
                    });
            }
        });
    };

    // Eğer images dizi değilse, tek öğeden oluşan bir diziye çeviriyoruz.
    const imagesArray = Array.isArray(images) ? images : [images];
    const displayImage = imagesArray[0]; // İlk görseli kullanarak tabloya ekliyoruz.

    return (
        <tr>
            {/* İlk görseli gösteriyoruz */}
            <td>
                <img 
                    style={{ width: '50px' }} 
                    src={`https://jewellery-server-main.onrender.com/${displayImage}`} 
                    alt={`${name}-thumbnail`} 
                />
            </td>
            <td>{name}</td>
            <td>{price}$</td>
            <td>{stock}</td>
            <td>
                <button style={btnDelete} onClick={() => handleDelete(_id)}>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </td>
        </tr>
    );
};

export default ManageProductCard;
