import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './Service.css';

const Service = () => {
    const navigate = useHistory(); // useHistory fonksiyonu, yönlendirme yapmak için kullanılıyor.

    // handleChange fonksiyonu, parametre alacak şekilde düzenlendi
    const handleChange = (category) => {
        navigate.push(`/jewellery?category=${category}`);
    };

    return (
        <Container className='custom-service'>
            <Row xs={1} md={3} className="g-4">
                <Col>
                    <div className='woman-card'>
                        <div>
                            <h4 style={{ color: '#f44c7a' }}>Kadın Aksesuarları</h4>
                            <p className='text-muted'>500+ Ürün</p>
                            <button onClick={() => handleChange('kadın')} className='btn-service'>Hemen Alışveriş Yap</button>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='man-card'>
                        <div>
                            <h4 style={{ color: '#03a98a' }}>Erkek Aksesuarları</h4>
                            <p className='text-muted'>500+ Ürün</p>
                            <button onClick={() => handleChange('erkek')} className='btn-service'>Hemen Alışveriş Yap</button>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='kid-card'>
                        <div>
                            <h4 style={{ color: '#f77527' }}>Çocuk Aksesuarları</h4>
                            <p className='text-muted'>100+ Kids Ürün</p>
                            <button onClick={() => handleChange('çocuk')} className='btn-service'>Hemen Alışveriş Yap</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Service;
