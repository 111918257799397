import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import swal from 'sweetalert';
import logo from '../../../images/logo.png';

const CreateProduct = () => {
    const [createProduct, setCreateProduct] = useState({ img: [] });
    const [categories] = useState(['Kadın', 'Erkek', 'Çocuk', 'Yetişkin', 'Diğer']);
    const [ratings] = useState(['Altın', 'Gümüş', 'Değerli Taş', 'Aksesuar', 'Yüzük', 'Kolye', 'Saat', 'Bileklik', 'Bilezik', 'Küpe']);
    const [selectedFiles, setSelectedFiles] = useState([]);  // Dosyaları tutmak için state

    const handleOnChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newProduct = { ...createProduct };

        if (type === 'checkbox') {
            const selectedRatings = newProduct[name] || [];
            if (checked) {
                selectedRatings.push(value);
            } else {
                const index = selectedRatings.indexOf(value);
                if (index > -1) {
                    selectedRatings.splice(index, 1);
                }
            }
            newProduct[name] = selectedRatings;
        } else if (type === 'file') {
            const filesArray = Array.from(e.target.files); // Yüklenen dosyaları diziye çevir
            setSelectedFiles(prevFiles => [...prevFiles, ...filesArray]); // Önceki dosyaları koru
        } else {
            newProduct[name] = value;
        }

        setCreateProduct(newProduct);
    };

    const handleRemoveFile = (fileName) => {
        const updatedFiles = selectedFiles.filter(file => file.name !== fileName);
        setSelectedFiles(updatedFiles);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        // Dosya yükleme için FormData kullan
        const formData = new FormData();
        formData.append('name', createProduct.name);
        formData.append('description', createProduct.description);
        formData.append('category', createProduct.category);
        formData.append('rating', createProduct.rating);
        formData.append('stock', createProduct.stock); // Stok bilgisini ekle

        if (selectedFiles.length > 0) {
            selectedFiles.forEach(file => {
                formData.append('images', file);  // Dosyaları formData'ya ekle
            });
        }

        fetch('https://jewellery-server-main.onrender.com/products', {
            method: 'POST',
            body: formData  // JSON yerine formData gönder
        })
        .then(res => res.json())
        .then(data => {
            if (data.insertedId) {
                swal("TEBRİKLER!", "Ürün Başarıyla Oluşturuldu", "success");
                e.target.reset();
                setCreateProduct({ img: [] });
                setSelectedFiles([]);  // Seçilen dosyaları sıfırla
            }
        });
    };

    return (
        <>
            <p className='text-center'>
                <img style={{ width: '180px' }} src={logo} alt="" />
            </p>
            <h6 className='text-center text-muted'>Ürün Oluştur</h6>
            <div className='row'>
                <div className='col-md-6 col-12 mx-auto card p-4'>
                    <Form onSubmit={handleOnSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Ürün İsmi</Form.Label>
                            <Form.Control type="text" name='name' onBlur={handleOnChange} placeholder="Ürün İsmi" required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Ürün Açıklaması</Form.Label>
                            <Form.Control type="text" name='description' onBlur={handleOnChange} placeholder="Açıklama" required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Ürün Kategorisi</Form.Label>
                            <Form.Control
                                as="select"
                                name="category"
                                onChange={handleOnChange}
                                required
                            >
                                <option value="">Kategori seçiniz</option>
                                {categories.map((category, index) => (
                                    <option key={index} value={category}>{category}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Ürün Etiketi</Form.Label>
                            {ratings.map((rating, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    label={rating}
                                    value={rating}
                                    name="rating"
                                    onChange={handleOnChange}
                                />
                            ))}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Stok Miktarı</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="stock" 
                                onBlur={handleOnChange} 
                                placeholder="Stok Miktarı" 
                                required 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Ürün Görseli</Form.Label>
                            <Form.Control 
                                type="file" 
                                name="images" 
                                onChange={handleOnChange} 
                                multiple // Birden fazla dosya seçmek için
                                required
                            />
                        </Form.Group>

                        {/* Seçilen dosyaların listesi */}
                        {selectedFiles.length > 0 && (
                            <div className="mb-3">
                                <h6>Seçilen Görseller:</h6>
                                <ul className="list-unstyled">
                                    {selectedFiles.map((file, index) => (
                                        <li key={index} className="d-flex justify-content-between align-items-center">
                                            <span>{file.name}</span>
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm"
                                                onClick={() => handleRemoveFile(file.name)}
                                            >
                                                &times; {/* Küçük kırmızı x */}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <Form.Control type="submit" value='Ürünü Yayınla' className='btn-form' />
                    </Form>
                </div>
            </div>
        </>
    );
};

export default CreateProduct;
