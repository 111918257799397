import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Link'i import et
import './TopView.css';

const TopView = () => {
    return (
        <Container>
            <Row xs={1} md={2} className="my-5">
                <Col>
                    <div className='deal-card'>
                        <div>
                            <h2>Erkek Bileklik</h2>
                            <p>Stilinize güç ve zarafet katın!</p>
                            <Link to="/jewellery?search=erkek%20bileklik">
                                <button style={{ backgroundColor: '#dda06b' }} className='btn rounded py-2 text-white'>
                                    ŞİMDİ KEŞFEDİN
                                </button>
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='fashion-card'>
                        <div>
                            <h2>Erkek Kolye</h2>
                            <p>Şıklığınızı tamamlayan özgün takılarımızı keşfedin!</p>
                            <Link to="/jewellery?search=erkek%20kolye">
                                <button style={{ backgroundColor: '#dda06b' }} className='btn rounded py-2 text-white'>
                                    ŞİMDİ KEŞFEDİN
                                </button>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default TopView;
