const firebaseConfig = {
    apiKey: "AIzaSyDpDrd5YYL3D0c0weI3KuJ8rhCHMjg7ZGY",
    authDomain: "jewellery-shop-98649.firebaseapp.com",
    projectId: "jewellery-shop-98649",
    storageBucket: "jewellery-shop-98649.appspot.com",
    messagingSenderId: "643209176494",
    appId: "1:643209176494:web:375a8f70d25f84bd9ed43f",
    measurementId: "G-C43PDK2BQT"
};

export default firebaseConfig;
