import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div>
            <div className='footer-container p-4'>
                <ul>
                    <p className='fs-6 fw-bold'>İLETİŞİM BİLGİLERİ</p>
                    <li>
                        <a href="https://maps.app.goo.gl/68mLGZQx7EYWcEXC9" target="_blank" rel="noopener noreferrer" style={{ color: '#dcdde1', textDecoration: 'none' }}>
                            <i className="fas fa-map-marker-alt"></i> Menderes Cd. No:208 Şirinyer/İzmir
                        </a>
                    </li>

                    <li>
                        <a href="tel:05548727019" style={{ color: '#dcdde1', textDecoration: 'none' }}>
                            <i className="fas fa-phone-alt"></i> 0554 872 7019
                        </a>
                    </li>



                    {/* <li><i className="fas fa-envelope"></i>worldtourism2021@gmail.com</li> */}
                    <ul className='footer-icon'>
                        <li className='me-2 icon'><i className="fab fa-facebook-square"></i></li>
                        <li className='me-2 icon'><i className="fab fa-twitter-square"></i></li>
                        <li className='me-2 icon'><i className="fab fa-linkedin"></i></li>
                        <li className='me-2 icon'><i className="fab fa-instagram-square"></i></li>
                    </ul>
                </ul>
                <ul style={{ width: '300px' }}>
                    <p className='fs-6 fw-bold'>DİĞER BİLGİLER</p>
                    {/* <li><i className="fal fa-window-minimize"></i> Hakkımızda</li> */}
                    {/* <li><i className="fal fa-window-minimize"></i> Delivery</li> */}
                    {/* <li><i className="fal fa-window-minimize"></i> Prices Drop</li> */}
                    {/* <li><i className="fal fa-window-minimize"></i> Yeni Ürünler</li>
                    <li><i className="fal fa-window-minimize"></i> En Çok Satılanlar </li> */}
                </ul>
                {/* <ul>
                    <p className='fs-6 fw-bold'>MY ACCOUNT</p>
                    <li><i className="fal fa-window-minimize"></i> Personal Info</li>
                    <li><i className="fal fa-window-minimize"></i> Orders</li>
                    <li><i className="fal fa-window-minimize"></i> Our Services</li>
                    <li><i className="fal fa-window-minimize"></i> Credit Slips</li>
                    <li><i className="fal fa-window-minimize"></i> Addresses</li>
                    <li><i className="fal fa-window-minimize"></i> My Wishlist</li>
                </ul> */}
                {/* Google Maps Embed */}
                <div class="map-container">
                    <iframe title="maps-component"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3127.1538970270576!2d27.1546824335877!3d38.391690547849!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd8b41828a119%3A0x35e56554a551f006!2sCeylin%20Kuyumculuk!5e0!3m2!1str!2str!4v1724247155323!5m2!1str!2str"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>


                <ul>
                    {/* <p className='fs-6 fw-bold'>INSTAGRAM POST</p> */}
                    {/* LightWidget Embed Code */}
                    {/* <iframe title="instagram component"
                        src="//lightwidget.com/widgets/a4e58ff8e2515e4781e8c59848b5bd8c.html" 
                        scrolling="no" 
                        allowtransparency="true" 
                        className="lightwidget-widget" 
                        style={{ width: '100%', border: 0, overflow: 'hidden' }}
                    ></iframe> */}
                </ul>
            </div>
            {/* <div className='footer-copyright'>
                <p>Pay with</p>
                <img className='img-fluid' src={pay} alt="" />
            </div> */}
        </div>
    );
};

export default Footer;
