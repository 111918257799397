import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from "react-slick";
import Product from '../Product/Product';
import './Products.css';

const Products = ({ children, products }) => {

    // Slider ayarlarını aşağıdaki şekilde güncelleyebilirsiniz:
const settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false, // Varsayılan değeri false yapın
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                centerMode: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true, // Mobilde ortalamayı aktif hale getirin
                centerPadding: '20px', // Kartlar arasında sağ ve sol boşluk ekleyin
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                centerMode: true,
                centerPadding: '30px' // Mobilde kartları ortalamak için boşluk verin
            }
        }
    ]
};

    return (
        <Container>
            <div className='text-center my-5'>
                <h1 style={{ color: '#dda06b' }} className='fs-2 fw-normal'>En İyi {children} Aksesuarları</h1>
                <h2 style={{ letterSpacing: '2px', }} className='fs-5 text-muted'>Tarzınızı yansıtan en şık {children} aksesuarları burada! Günlük kullanım ve özel anlar için özenle seçilmiş ürünlerle zarafeti yakalayın.</h2>
            </div>
            <Slider {...settings}>
                {
                    products?.map(product => 
                    <Product
                        key={product._id}
                        product={product}>
                    </Product>)
                }
            </Slider>
        </Container>
    );
};

export default Products;