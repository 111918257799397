import React from 'react';
import { Container } from 'react-bootstrap';
import './Banner.css';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div className='banner-container'>
            <Container>
                <p className='fs-6'><small>KADIN TAKILARI</small></p>
                <h1 class="font-style"> İÇİNİZDEKİ <span>IŞILTIYI </span><br /> TENİNİZE YANSITIN... </h1>

                <p>Gerçek tutkunuzun peşinden gidin, bırakın mücevherleriniz size yol göstersin.</p>
                <Link to="/jewellery?category=kadın">
                    <button className='btn-regular'>Şimdi Keşfedin</button>
                </Link>
            </Container>
        </div>
    )
};

export default Banner;