import React from 'react';
import { Col, Container} from 'react-bootstrap';
import image1 from '../../../images/image1.jpg';
import image2 from '../../../images/image2.jpg';
import image3 from '../../../images/image3.jpg';
import image4 from '../../../images/image4.jpg';
import image5 from '../../../images/image5.jpg';
import image6 from '../../../images/image6.jpg';
import image7 from '../../../images/image7.jpg';
import image8 from '../../../images/image8.jpg';

import './MoreInfo.css';

const MoreInfo = () => {
    const products = [
        { id: 1, image: image1, name: 'Ürün 1' },
        { id: 2, image: image2, name: 'Ürün 2' },
        { id: 3, image: image3, name: 'Ürün 3' },
        { id: 4, image: image4, name: 'Ürün 4' },
        { id: 5, image: image5, name: 'Ürün 5' },
        { id: 6, image: image6, name: 'Ürün 6' },
        { id: 7, image: image7, name: 'Ürün 7' },
        { id: 8, image: image8, name: 'Ürün 8' },


        // Diğer ürünler
    ];
    return (
        <div className='mt-5' style={{ backgroundColor: '#fbf9f5' }}>
            <Container>
                <div className='text-center my-5'>
                    <h1 style={{ color: '#dda06b' , paddingTop: '20px'}} className='fs-3 fw-normal '>En Çok İlgi Çeken Ürünler</h1>
                    <h2 style={{ letterSpacing: '3px', paddingTop: '10px' }} className='fw-bold fs-1 text-muted'> Kadın Takıları</h2>
                </div>     
                    <Col>
                    <div className="product-grid">
                        {products.map((product) => (
                            <a href={`/product/${product.id}`} className="product-card" key={product.id}>
                                <img src={product.image} alt={product.name} className="product-image" />
                            </a>
                        ))}
                    </div>
                    </Col>
            </Container>
        </div>
    );
};

export default MoreInfo;