import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import img2 from '../../../../images/halter.png';
import img1 from '../../../../images/shoes.png';
import img3 from '../../../../images/watches.png';
import './InfoSection.css';

const InfoSection = () => {
    return (
        <Container>
            <Row className='mt-5 gy-2'>
                <Col xs={12} md={6}>
                    <a href="/jewellery?search=altın">
                        <img width='91%' src={img1} alt="Ayakkabılar" />
                    </a>
                </Col>
                <Col xs={12} md={6}>
                    <a href="/jewellery?search=kad%C4%B1n%20y%C3%BCz%C3%BCk">
                        <img width='101%' src={img2} alt="Halter" />
                    </a>
                    <a href="/jewellery?search=altın%20aksesuar">
                        <img className='mt-4' width='100%' src={img3} alt="Saatler" />
                    </a>
                </Col>
            </Row>
        </Container>
    );
};

export default InfoSection;
