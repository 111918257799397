import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import useAuth from '../../../Hooks/useAuth';
import logo from '../../../images/logo.png';
import Footer from '../../Shared/Footer/Footer';
import Navigation from '../../Shared/Navigation/Navigation';
import './Register.css';

const Register = () => {
    const { registerUser, error } = useAuth();
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const location = useLocation();
    const history = useHistory();
    const [showPasswordRules, setShowPasswordRules] = useState(false);
    const [showEmailRules, setShowEmailRules] = useState(false);

    const onSubmit = data => {
        // Şifre kontrolü
        if (data.password !== data.password2) {
            swal({
                title: "Hata!",
                text: "Lütfen aynı şifreyi girin",
                icon: "error",
                button: "Tamam",
            });
            return;
        }
        
        // Kullanıcı kaydı
        registerUser(data.email, data.password, data.name, location, history);
        reset();
    }

    return (
        <>
            <Navigation />
            <div className='row mx-auto p-3 w-75'>
                <div className='col-md-6 col-12 mx-auto shadow p-4'>
                    <p className='text-center'>
                        <img style={{ width: '180px' }} src={logo} alt="" />
                    </p>
                    <h4 className='text-center mb-4 text-muted'>Kayıt Olun</h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input 
                            placeholder='İsim' 
                            {...register("name", { required: true })} 
                            className="form-control mb-2" 
                        />
                        {errors.name && <span className='text-danger'>Bu alan zorunludur</span>}

                        <input 
                            placeholder='E-Posta' 
                            {...register("email", { 
                                required: true,
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Geçersiz e-posta formatı"
                                }
                            })} 
                            className="form-control mb-2" 
                            onFocus={() => setShowEmailRules(true)}
                            onBlur={() => setShowEmailRules(false)}
                        />
                        {errors.email && <span className='text-danger'>{errors.email.message || 'Bu alan zorunludur'}</span>}

                        <input 
                            placeholder='Şifre' 
                            type='password' 
                            {...register("password", { 
                                required: true,
                                minLength: {
                                    value: 8,
                                    message: "Şifre en az 8 karakter olmalı"
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                    message: "Şifre en az 1 büyük harf, 1 küçük harf ve 1 sayı içermelidir"
                                }
                            })} 
                            className="form-control mb-2" 
                            onFocus={() => setShowPasswordRules(true)}
                            onBlur={() => setShowPasswordRules(false)}
                        />
                        {errors.password && <span className='text-danger'>{errors.password.message || 'Bu alan zorunludur'}</span>}

                        <input 
                            placeholder='Şifre' 
                            type='password'  
                            {...register("password2", { required: true })} 
                            className="form-control mb-4" 
                        />
                        {errors.password2 && <span className='text-danger'>Bu alan zorunludur</span>}

                        <p>{error}</p>

                        <input type="submit" className="form-control btn-form" value='Register' />
                    </form>
                    <p className='mt-4 text-muted'>Zaten bir hesabınız mı var ? <Link to='/login'>Giriş Yap</Link></p>
                </div>
            </div>

            {/* Pop-up alanları */}
            {showPasswordRules && (
                <div className="popup">
                    <h5>Şifre Kuralları:</h5>
                    <ul>
                        <li>En az 8 karakter olmalı</li>
                        <li>En az 1 büyük harf</li>
                        <li>En az 1 küçük harf</li>
                        <li>En az 1 rakam</li>
                    </ul>
                </div>
            )}

            {showEmailRules && (
                <div className="popup">
                    <h5>E-posta Kuralları:</h5>
                    <ul>
                        <li>Geçerli bir e-posta adresi girin</li>
                    </ul>
                </div>
            )}

            <Footer />
        </>
    );
};

export default Register;
