import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Product from '../Product/Product';
import Footer from '../Shared/Footer/Footer';
import Navigation from '../Shared/Navigation/Navigation';
import './Jewellery.css';

const Jewellery = () => {
    const [products, setProducts] = useState([]);
    const [selectedRatings, setSelectedRatings] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // Arama sorgusu için state
    const [noProductsFound, setNoProductsFound] = useState(false); // Ürün bulunmadı durumu için state
    const location = useLocation();
    const category = new URLSearchParams(location.search).get('category');

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://jewellery-server-main.onrender.com/products');
                if (!response.ok) { throw new Error(`HTTP error! Status: ${response.status}`); }
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Fetch products error:', error.message);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const search = params.get('search') || '';
        setSearchQuery(search.toLowerCase());
    }, [location.search]);

    // Ürünleri arama sorgusuna ve filtrelere göre filtreleme
    const filteredProducts = products.filter(product => {
        const name = product.name ? product.name.toLowerCase() : '';
        const description = product.description ? product.description.toLowerCase() : '';
        const productCategory = typeof product.category === 'string' 
            ? product.category.toLowerCase() 
            : Array.isArray(product.category) 
            ? product.category.join(' ').toLowerCase() 
            : '';
        const productRating = typeof product.rating === 'string' 
            ? product.rating.toLowerCase() 
            : Array.isArray(product.rating) 
            ? product.rating.join(' ').toLowerCase() 
            : '';

        const categoryParam = category ? category.toLowerCase() : '';

        // Arama sorgusunu boşlukla ayır
        const searchTerms = searchQuery.split(' ');

        // Her bir kelime için kontrol yap
        const matchesSearchQuery = searchTerms.every(term => 
            name.includes(term) || 
            description.includes(term) || 
            productCategory.includes(term) || 
            productRating.includes(term)
        );

        const matchesCategory = category ? productCategory.includes(categoryParam) : true;
        const matchesSelectedRatings = selectedRatings.length === 0 || 
                                       selectedRatings.every(filter => productRating.includes(filter.toLowerCase()));

        return matchesSearchQuery && matchesCategory && matchesSelectedRatings;
    });

    // Ürün yoksa noProductsFound durumunu güncelle
    useEffect(() => {
        if (filteredProducts.length === 0) {
            const timer = setTimeout(() => {
                setNoProductsFound(true);
            }, 1000);
            return () => clearTimeout(timer); // Temizleme işlemi
        } else {
            setNoProductsFound(false); // Ürün bulunduğunda durumu sıfırla
        }
    }, [filteredProducts]);

    // Rating filtresi
    const handleFilterChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        setSelectedRatings(prevFilters => 
            checked ? [...prevFilters, value] : prevFilters.filter(filter => filter !== value)
        );
    };

    return (
        <>
            <Navigation />
            <Container className="jewellery-container">
                <Row>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: '20px' }}>
                        {/* Filtreleme Menüsü */}
                        <div className="filter-menu">
                            <h5 className="filter-title">Filtreleme</h5>
                            <Form className="filter-form">
                                {/* Kategori Bölümü */}
                                <div className="filter-category">
                                    <h6 className="category-title">Kategori</h6>
                                    <div className="filter-checkbox">
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Altın" 
                                            value="altın"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Gümüş" 
                                            value="gümüş"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Değerli Taş" 
                                            value="değerli taş"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Aksesuar" 
                                            value="aksesuar"
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
    
                                {/* Takı Tipi Bölümü */}
                                <div className="filter-category">
                                    <h6 className="category-title">Takı Tipi</h6>
                                    <div className="filter-checkbox">
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Yüzük" 
                                            value="yüzük"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Kolye" 
                                            value="kolye"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Saat" 
                                            value="saat"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Bileklik" 
                                            value="bileklik"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Bilezik" 
                                            value="bilezik"
                                            onChange={handleFilterChange}
                                        />
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Küpe" 
                                            value="küpe"
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Col>
                    <Col md={9}>
                        <Row xs={1} md={4} className="g-4 jewellery-row">
                            {filteredProducts.map(product => (
                                <Product
                                    key={product._id}
                                    product={product}
                                    className="jewellery-card"
                                />
                            ))}
                        </Row>
                        {/* Ürün bulunamadı mesajı */}
                        {noProductsFound && (
                            <Col>
                                <h4>Arama kriterlerinize uygun ürün bulunamadı.</h4>
                            </Col>
                        )}
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default Jewellery;
