    import React, { useState } from 'react';
    import { Container, Button } from 'react-bootstrap';
    // import Rating from 'react-rating';
    import './Purchase.css';

    const Purchase = ({ product }) => {
        const { name, images, stock, description } = product;
        // const { rating, price} = product;

        // images bir dizi olarak kullanılmalı. Eğer değilse, diziye çeviriyoruz.
        const imagesArray = Array.isArray(images) ? images : [images];

        // State: Ana görseli kontrol etmek ve mevcut görselin index'ini tutmak için kullanılır.
        const [currentIndex, setCurrentIndex] = useState(0);

        // Mevcut index'e bağlı olarak ana görseli seçiyoruz.
        const mainImage = imagesArray[currentIndex];

        // Görseli ileri kaydırma
        const handleNextImage = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesArray.length);
        };

        // Görseli geri kaydırma
        const handlePreviousImage = () => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + imagesArray.length) % imagesArray.length);
        };

        const handleContactClick = () => {
            window.location.href = 'tel:+905548727019';
        };

        return (
            <Container>
                <div className='row gy-4 mb-5'>
                    {/* Sol tarafta küçük görseller (Thumbnails) */}
                    <div className='col-md-1 col-2 d-flex flex-column align-items-center'>
                        {imagesArray.map((thumbnail, index) => (
                            <div 
                                key={index} 
                                className={`thumbnail-container mb-2 ${currentIndex === index ? 'selected-thumbnail' : ''}`}
                                onClick={() => setCurrentIndex(index)} // Küçük görsele tıklandığında index değişir.
                            >
                                <img 
                                    src={`https://jewellery-server-main.onrender.com/${thumbnail}`} // Backend adresi ile birleştiriyoruz.
                                    alt={`product-thumbnail-${index}`} 
                                    className='img-fluid' 
                                />
                            </div>
                        ))}
                    </div>

                    {/* Orta bölüm: Ana görsel ve sağ-sol okları */}
                    <div className='col-md-5 col-10 position-relative'>
                        {/* Sol ok butonu */}
                        <button 
                            className='btn-prev' 
                            onClick={handlePreviousImage}
                        >
                            &lt;
                        </button>

                        <div className='card-detail'>
                            <img 
                                className='img-fluid' 
                                src={`https://jewellery-server-main.onrender.com/${mainImage}`} // Ana görseli backend adresi ile birleştiriyoruz.
                                alt={name} 
                            />
                        </div>

                        {/* Sağ ok butonu */}
                        <button 
                            className='btn-next' 
                            onClick={handleNextImage}
                        >
                            &gt;
                        </button>
                    </div>

                    {/* Sağ tarafta ürün bilgileri */}
                    <div className='col-md-6 col-12 d-flex flex-column justify-content-center'>
                        <h2 className='fw-bold text-custom mb-3'>{name?.toUpperCase()}</h2>

                        {/* <Rating
                            initialRating={rating}
                            emptySymbol="far fa-star text-custom"
                            fullSymbol="fas fa-star text-custom"
                            readonly
                        /> */}
    {/* 
                        <h4 className='text-muted mt-3'>{price}$</h4> */}
                        <p className='text-muted'><small>Stok Durumu: {stock} adet</small></p>
                        <p className='mt-3'>{description}</p>

                        {/* Satın Alma Butonu */}
                        <Button variant="primary" className='btn-form mt-4' onClick={handleContactClick}>
                            Satın Almak İçin İletişime Geç
                        </Button>
                    </div>
                </div>
            </Container>
        );
    };

    export default Purchase;
