import React, { useState} from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, useHistory} from 'react-router-dom';
// import swal from 'sweetalert';
import useAuth from '../../../Hooks/useAuth';
import logo from '../../../images/logo.png';
import './Navigation.css';

const Navigation = () => {
    const { user, logOut } = useAuth();
    const history = useHistory();
    // const location = useLocation();

    // Arama sorgusu state
    const [searchQuery, setSearchQuery] = useState("");

    // Giriş alanındaki değişiklikleri handle etme
    const handleInputChange = (e) => {
        setSearchQuery(e.target.value); // Kullanıcının girdiği değeri searchQuery olarak ayarlayın
    };

    // Arama butonuna tıklandığında arama işlemini başlat
    const handleSearch = (e) => {
        e.preventDefault(); // Sayfanın yenilenmesini önle
        if (searchQuery.trim()) { 
            // Eğer arama kutusu boş değilse
            history.push(`/jewellery?search=${searchQuery}`); // URL'yi güncelle ve search parametresini ekle
        } else {
            history.push(`/jewellery`); // Arama kutusu boşsa, tüm ürünleri göster
        }
    };

    // const handleContact = () => {
    //     swal({ 
    //         // title: `Thank you ${user.displayName}`, 
    //         text: "Lütfen web sitemizin altbilgisine bakın. Site altbilgisinde bizimle iletişim detaylarına ulaşabilirsiniz!", 
    //         icon: "info" 
    //     });
    // };

    return (
        <Navbar bg="light" variant="light" collapseOnSelect expand="lg">
            <Container>
                <Navbar.Brand href="/home">
                    <img width='95px' src={logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
                <Nav className="mx-auto d-flex align-items-center gap-3">
                    <Nav.Link as={Link} to="/home">Anasayfa</Nav.Link>
                    <Nav.Link as={Link} to="/jewellery">Tüm Ürünler</Nav.Link>
                    <Nav.Link as={Link} to={`/jewellery?category=kadın`}>Kadın</Nav.Link>
                    <Nav.Link as={Link} to={`/jewellery?category=erkek`}>Erkek</Nav.Link>
                    <Nav.Link as={Link} to={`/jewellery?category=çocuk`}>Çocuk</Nav.Link>
                    {/* <Nav.Link onClick={handleContact}>İletişim</Nav.Link> */}
                    {
                        user.email && <Nav.Link href="/dashboard" className='fw-bold fs-6 text-warning'>{user.displayName}</Nav.Link>
                    }
                    {
                        user.email ? <button className='btn-regular' onClick={logOut}>Çıkış Yap</button> : <Nav.Link as={Link} to="/login" >Giriş Yap</Nav.Link>
                    }
                    </Nav>

                    {/* Arama Çubuğu */}
                    <form onSubmit={handleSearch} className="small-search-box-form-1624553742" style={{ marginLeft: '10px', maxWidth: '400px' }}>
                        <input 
                            className="input" 
                            autoComplete="off" 
                            name="search" 
                            type="text" 
                            placeholder="Aradığınız ürünü yazın..." 
                            value={searchQuery}
                            onChange={handleInputChange} // Giriş değişikliklerini handle et
                            style={{
                                height: '35px',
                                fontSize: '12px',
                                padding: '0px 10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                width: '200px',
                            }}
                        />
                        <button 
                            className="button-search" 
                            type="submit" 
                            style={{
                                background: 'transparent',
                                border: 'none',
                                marginLeft: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#ddd" className="svg-icon" viewBox="0 0 16 16" width="20px">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                            </svg>
                        </button>

                        <div className="search-results"></div>
                    </form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
